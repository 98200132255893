.switch-input:checked ~ .switch-body .switch-thumb-large {
  transform: translateX(21px);
  background-color: white;
}

.switch-input:checked ~ .switch-body .switch-thumb-large svg {
  fill: #123c8e;
}

.switch-input:checked ~ .switch-body {
  background-color: #123c8e;
}
