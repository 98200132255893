@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100vh;
}

* {
  font-family: 'Open Sans', sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.Toastify__toast-container {
  padding-top: 55px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(239, 241, 245, 1);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(118, 136, 172, 0.3);
  border-radius: 100px;
}
