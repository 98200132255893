.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-content::-webkit-scrollbar-track {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-content::-webkit-scrollbar-thumb {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
