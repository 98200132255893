.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.leaflet-control-container {
  width: 100%;
}
.leaflet-popup-content p {
  margin: 0;
}
.leaflet-tooltip {
  padding: 0;
  border: none;
}
.leaflet-tooltip-top:before {
  display: none;
}
